<template>
  <CsTableCard
    card-header="Elenco Parti"
    :rows="rows"
    :columns="columns"
  >
    <template slot="topLeft">
      <aggiungi
        v-if="canAdd"
        :opera-id="opera.id"
        :parziale-id="parziale.id"
        @onAdd="addRow"
      />
    </template>

    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :props="props"
        :show-actions="canComponenti || canRemove"
      >
        <cs-table-action-item-details
          v-if="canComponenti"
          :row="props.row"
          @click="detailsRow"
        />
        <cs-table-action-item-delete
          v-if="canRemove"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>
  </CsTableCard>
</template>

<script>
import Aggiungi from './Aggiungi.vue'

export default {
  components: {
    Aggiungi,
  },
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Codice',
          field: 'codice',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Numero',
          field: 'numero',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Nome',
          field: 'nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Identificativo',
          field: 'identificativo',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      parziale: {
        id: '00000000-0000-0000-0000-000000000000',
        parti: [],
      },
      opera: {
        id: '00000000-0000-0000-0000-000000000000',
      },
      canComponenti: this.$grants.OPERE_COMPONENTI_VISUALIZZA.can,
      canAdd: this.$grants.OPERE_PARTI_AGGIUNGI.can,
      canRemove: this.$grants.OPERE_PARTI_ELIMINA.can,
    }
  },
  created() {
    if (!this.$grants.OPERE_PARTI_VISUALIZZA.can) this.$routing.ERRORE401.push()

    this.$remote.opere.parziale_single(this.$route.params.id, this.$route.params.operaParzialeId)
      .then(res => {
        this.opera = res.data
        this.parziale = res.data.parziale

        const value = { id: this.opera.id, nome: this.opera.nome, numeroOperaParziale: `Opera Parziale ${this.parziale.numero}` }
        this.setBreadcrumbData(this.$store, this.$route.name, value)

        this.loading = false

        this.rows = this.parziale.parti
      })
      .catch(err => {
        this.showReposonseMessage(err)
        this.$routing.ERRORE404.push()
      })
  },
  methods: {
    addRow(params) {
      this.addRowAtStart(this.rows, params)
      this.showAddSuccessMessage()
    },
    detailsRow(params) {
      const objParams = { id: this.opera.id, operaParzialeId: this.parziale.id, parteId: params.id }
      this.$routing.OPERE_PARTI_DETTAGLIO.push(objParams)
    },
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return

        this.loading = true
        this.$remote.opere.parte_remove(this.opera.id, this.parziale.id, params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
  },
}
</script>
